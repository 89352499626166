var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav-breadcrumb"},[(_vm.$route.path.indexOf('/security') === 0)?_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: 'Security' }}},[_c('i',{staticClass:"fa fa-home"}),_vm._v(" security ")])],1),(
        _vm.isRouteActive([
          'SecurityUsersList',
          'SecurityUsersCreate',
          'SecurityUsersUpdate',
          'SecurityUsersEditCustomMapping'
        ])
      )?_c('li',[_c('i',{staticClass:"fa fa-angle-right separator",attrs:{"aria-hidden":"true"}}),_c('router-link',{attrs:{"to":{ name: 'SecurityUsersList' }}},[_vm._v(" users ")])],1):_vm._e(),(
        _vm.isRouteActive([
          'SecurityProfilesList',
          'SecurityProfilesCreate',
          'SecurityProfilesUpdate'
        ])
      )?_c('li',[_c('i',{staticClass:"fa fa-angle-right separator",attrs:{"aria-hidden":"true"}}),_c('router-link',{attrs:{"to":{ name: 'SecurityProfilesList' }}},[_vm._v(" profiles ")])],1):_vm._e(),(
        _vm.isRouteActive([
          'SecurityRolesList',
          'SecurityRolesCreate',
          'SecurityRolesUpdate'
        ])
      )?_c('li',[_c('i',{staticClass:"fa fa-angle-right separator",attrs:{"aria-hidden":"true"}}),_c('router-link',{attrs:{"to":{ name: 'SecurityRolesList' }}},[_vm._v(" roles ")])],1):_vm._e()]):_vm._e(),(_vm.$route.path.indexOf('/data') === 0)?_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: 'Data' }}},[_c('i',{staticClass:"fa fa-home"}),_vm._v(" data ")])],1),(_vm.$route.params.indexName)?_c('li',[_c('i',{staticClass:"fa fa-angle-right separator",attrs:{"aria-hidden":"true"}}),_c('router-link',{attrs:{"to":{
          name: 'Collections',
          params: { indexName: _vm.$route.params.indexName }
        }}},[_vm._v(" "+_vm._s(_vm.$route.params.indexName)+" ")])],1):_vm._e(),(_vm.$route.params.collectionName)?_c('li',[_c('i',{staticClass:"fa fa-angle-right separator",attrs:{"aria-hidden":"true"}}),(_vm.isCollectionRealtime())?_c('router-link',{attrs:{"to":{
          name: 'WatchCollection',
          params: {
            indexName: _vm.$route.params.indexName,
            collectionName: _vm.$route.params.collectionName
          }
        }}},[_vm._v(" "+_vm._s(_vm.$route.params.collectionName)+" ")]):_c('router-link',{attrs:{"to":{
          name: 'DocumentList',
          params: {
            indexName: _vm.$route.params.indexName,
            collectionName: _vm.$route.params.collectionName
          }
        }}},[_vm._v(" "+_vm._s(_vm.$route.params.collectionName)+" ")])],1):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }